import { postRequest, getRequest } from './index'
import { APISRCUL, SRCURL } from '@/config'
// 求购大厅-求购详情
export const goodsHallDetailPurchaseHallGoods = (id, successCallback, failureCallback) => {
  getRequest(`${APISRCUL}/goods/hall/detailPurchaseHallGoods/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 求购大厅-求购列表
export const goodsHallPagePurchaseHallGoods = (data, successCallback, failureCallback) => {
  postRequest(`${APISRCUL}/goods/hall/pagePurchaseHallGoods`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 求购详情
export const tradeWantToBuyDetail = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/wantToBuy/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 新增求购
export const tradeWantToBuySave = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/wantToBuy/save`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
