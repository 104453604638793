<template>
  <div class="page-content">
    <div class="">
      <div class="item-top">
        <div class="top-left">
          {{ (dataObj.cmpShortName || '').slice(0, 4) || (dataObj.cmpName || '').slice(0, 4) }}
        </div>
        <div class="top-center">
          <p class="title">
            {{ dataObj.cmpName }}
          </p>
          <div class="address-box">
            <p>
              <span class="label">询价单号：</span>
              {{ dataObj.inquiryNo }}
            </p>
            <p>
              <span class="label">发布时间：</span>
              {{ dataObj.createTime }}
            </p>
            <p>
              <span v-if="dataObj.deadLine" class="label">截止时间：</span>
              {{ dataObj.deadLine }}
            </p>
          </div>
        </div>
        <div class="top-right">
          <el-popover
            placement="top-start"
            title="求购联系人"
            width="200"
            trigger="hover"
            :content="dataObj.contact + dataObj.phone"
          >
            <div slot="reference" class="view-details">
              <i class="icon-btn" />
              致电买家
            </div>
          </el-popover>
          <p v-if="dataObj.deadLine" class="right-bottom">
            <span class="label">距离截止日期还剩</span>
            {{ deadTime }}
          </p>
        </div>
      </div>
    </div>
    <h3 class="page-subtitle">
      求购商品
    </h3>
    <Table v-if="dataObj.tradeWantToBuyGoodsInfoReqs && dataObj.tradeWantToBuyGoodsInfoReqs.length > 0" class="tableBox" :item-data="itemData" :list-data="dataObj.tradeWantToBuyGoodsInfoReqs">
      <template #goodsOneCategoryName="{ row }">
        <div>{{ row.goodsOneCategoryName }}/{{ row.goodsTwoCategoryName }}</div>
      </template>
    </Table>
    <el-descriptions title="买家要求" :column="3" class="bottom-descriptions">
      <el-descriptions-item label="收货地区">
        {{ dataObj.receivingArea }}
      </el-descriptions-item>
      <el-descriptions-item label="期望收货日期">
        {{ dataObj.expectedReceiptDate || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="支付方式">
        {{ dataObj.paymentMethodWord }}
      </el-descriptions-item>
      <el-descriptions-item label="求购备注" :span="3">
        {{ dataObj.remarks }}
      </el-descriptions-item>
      <el-descriptions-item label="附件" :span="3">
        <img v-for="img in images" :key="img" class="file-img" :src="$fileUrl + img" alt="">
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { goodsHallDetailPurchaseHallGoods } from '@/http/wantToBuy'
import Table from '@/components/Table.vue'
export default {
  components: { Table },
  data() {
    return {
      dataObj: {
        contact: '',
        phone: ''
      },
      images: [],
      itemData: [
        { label: '品名', prop: 'goodsOneCategoryName' },
        { label: '材质', prop: 'goodsMaterialName' },
        { label: '规格', prop: 'goodsSpecName' },
        { label: '钢厂/厂家', prop: 'manufactor' },
        { label: '含税单价（元/吨）', prop: 'unitPriceIncludingTax' },
        { label: '求购重量（吨）', prop: 'quantity' }
      ],
      paymentMethodList: [
        { id: '0', label: '先款后货' },
        { id: '1', label: '货到付款' },
        { id: '2', label: '账期支付' }
      ],
      deadTime: '',
      timer: null
    }
  },
  created() {
    goodsHallDetailPurchaseHallGoods(this.$route.query.id, res => {
      this.dataObj = res.data
      const text = []
      this.paymentMethodList.map((item) => {
        if (res.data.paymentMethod.indexOf(item.id) !== -1) {
          text.push(item.label)
        }
      })
      this.dataObj.paymentMethodWord = text.toString()
      this.images = res.data && res.data.images ? res.data.images.split(',') : []
      if (res.data.deadLine) this.getTimer()
    })
  },
  mounted() {
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.getTimer()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getTimer() {
      if (!this.dataObj.deadLine) return
      const nowTime = new Date()
      const oldDate = new Date(this.dataObj.deadLine + ' 00:00:00')
      const restSec = oldDate.getTime() - nowTime.getTime()
      const day = parseInt(restSec / (60 * 60 * 24 * 1000))
      const hour = parseInt(restSec / (60 * 60 * 1000) % 24)
      const minu = parseInt(restSec / (60 * 1000) % 60)
      const sec = parseInt(restSec / 1000 % 60)
      const str = day + '天' + this.addZero(hour) + ':' + this.addZero(minu) + ':' + this.addZero(sec)
      this.deadTime = str
    },
    // 小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? ('0' + s) : s
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  padding: 20px 0;
  width: $centerPlateWidth;
  color: #333333;
  background-color: #fff;
  padding: 32px 24px;
  box-sizing: border-box;
  margin: 16px auto;
}
.details-first-level-title {
  padding: 20px 0;
  font-size: 14px;
}
.tableBox {
  margin-bottom: 48px;
}
.bottom-descriptions {
  /deep/ .el-descriptions__body {
  padding: 0;
}
.file-img {
  width: 68px;
  height: 68px;
  object-fit: cover;
  margin-right: 10px;
}
}
.page-subtitle, /deep/.el-descriptions__title {
  font-size: 18px;
  margin-bottom: 14px;
  color: #333333;
  font-weight: 500;
}
/deep/ .el-descriptions-item__label:not(.is-bordered-label) {
  color: #9FA19F;
  min-width: 90px;
}
.item-top {
  display: flex;
  align-items: center;
  margin-bottom: 38px;
  .top-left {
    width: 52px;
    height: 64px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background-color: #FBC9A4;
    color: #FFFFFF;
    font-size: 22px;
    padding: 8px 16px;
    text-align: center;
    margin-right: 16px;
    text-align-last: justify;
    text-align: justify;
    text-justify: distribute-all-lines;
  }
}
.top-center {
  .title {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .address-box {
    display: flex;
    color: #333333;
    font-size: 16px;
    p {
      margin-right: 32px;
    }
    .label {
      color: #666666;
    }
  }
}
.top-right {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .view-details {
    font-size: 16px;
    width: 138px;
    height: 38px;
    line-height: 38px;
    border: 1px solid $themeColors;
    background: rgba(255,255,255,1);
    color: $themeColors;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 6px;
    .icon-btn {
      display: inline-block;
      width: 24px;
      height: 24px;
      object-fit: cover;
      vertical-align: middle;
      background: url('../../assets/img/contact.png') top center no-repeat;
      background-size: contain;
    }
    &:hover {
      background-color: $themeColors;
      color: #fff;
      .icon-btn {
        background: url('../../assets/img/hover_contact.png') top center no-repeat;
        background-size: contain;
      }
    }
  }
  .right-bottom {
    font-size: 14px;
    color: $themeColors;
    .label {
      color: #666;
    }
  }

}
</style>
